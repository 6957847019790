.App {
  display: flex;
  padding: 3rem;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: url(../../assets/images/hero.jpg) no-repeat center center;
  background-size: cover;
}

.Overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.Wrapper {
  z-index: 1;
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 3rem;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Logo {
  width: 3rem;
  height: auto;
  fill: #fff;
}

.SocialWrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 1rem;
}

main {
  display: grid;
  grid-gap: 1rem;
}

.Name {
  align-self: flex-end;
  text-align: center;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 200;
  color: #fff;
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
}

.ButtonWrapper a {
  align-self: flex-start;
  text-decoration: none;
}

.ButtonWrapper a button {
  cursor: pointer;
}

footer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 1rem;
}

footer a {
  color: #fff;
}

@media only screen and (max-width: 640px) {
}
