.SocialLink {
  text-decoration: none;
  display: flex;
}

.SocialLink svg {
  height: 3rem;
  width: auto;
  fill: #fff;
}

.SocialLink:hover svg {
  fill: #faa3a3;
}

@media only screen and (max-width: 640px) {
  .SocialLink svg {
    height: 2rem;
  }
}
