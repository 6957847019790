.Button {
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 2rem;
  padding: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  transition: all 500ms ease;
  background: none;
  color: #fff;
}

.Button:hover {
  background: #fff;
  color: #000;
}
